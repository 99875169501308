import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import Layout from "../components/Layout";

import Button from "../components/shared/Button";
import { useState } from "react";
import Icon from "../components/shared/Icon";

const FAQItem = ({
  id,
  question,
  answer,
}: {
  id: string;
  question: string;
  answer: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(id === "mint" ? true : false);

  return (
    <>
      <a className="relative -top-[200px]" id={id}></a>
      <div className="border-b border-gray-300 pb-10" key={id}>
        <div
          className="flex justify-between items-center cursor-pointer mt-10"
          onClick={() => setIsOpen(!isOpen)}
        >
          <h3 className="text-3xl sm:text-5xl font-medium">{question}</h3>
          {isOpen ? (
            <Icon
              name="icon-chevron-up"
              className="text-3xl relative top-0.5 ml-1"
            />
          ) : (
            <Icon
              name="icon-chevron-down"
              className="text-3xl relative top-0.5 ml-1"
            />
          )}
        </div>
        {isOpen && (
          <p className="mt-8 text-xl sm:text-2xl text-white">{answer}</p>
        )}
      </div>
    </>
  );
};

const faqs = [
  {
    id: "whaletogether",
    question: "1. Who’s WhaleTogether?",
    answer: (
      <p>
        <a
          href="https://whaletogether.com/"
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          WhaleTogether
        </a>{" "}
        is a utility-focused NFT project that launched back in February, 2022.
        We’ve created awesome tools for our holders including a rarity and
        sniper browser extension for OpenSea, a private node, a gas-less
        whitelist marketplace for our $MOON utility token and a web-based tool
        with fast live listings and an amazing auto-snipe feature.{" "}
        <a
          href="https://opensea.io/collection/whaletogether"
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          Read our OS description here
        </a>
      </p>
    ),
  },
  {
    id: "zksync",
    question: "2. What is zkSync Era?",
    answer: (
      <p>
        <a
          href="https://zksync.io/"
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          zkSync
        </a>{" "}
        is a Layer-2 scaling solution for Ethereum that enables fast and
        low-cost transactions with cutting-edge ZK tech, and enhances the user
        experience for dApps and NFTs with hyperscalability.,
      </p>
    ),
  },
  {
    id: "zkTogether",
    question: "3. What is zkTogether?",
    answer: (
      <div className="flex flex-col gap-4">
        <p>
          {`It’s a community-driven Open Mint NFT project by WhaleTogether that
          aims to win together!`}
        </p>
        <p>
          We do this by leveraging the highly-anticipated upcoming zkSync
          airdrop people are starting to grind for.
        </p>
        <p>
          By minting our NFT, we hope to give you a better chance at the
          upcoming zkSync airdrop as previous airdrops (Arbitrum, etc) have
          required tasks such as minting, bridging, swapping, etc as a
          requirement.
        </p>
      </div>
    ),
  },
  {
    id: "benefits",
    question: "4. What are the benefits of participating in the mint?",
    answer: (
      <div className="flex flex-col gap-4">
        <p>
          Apart from leaving a{" "}
          <u>
            <b>footprint</b>
          </u>{" "}
          on zkSync when you bridge ETH and make a transaction (mint), which
          increases your chances of getting a larger portion of the zkSync token
          airdrop…
        </p>
        <p>
          You will also have the chance to earn a commission by referring others
          to mint, as well as potentially qualifying for an allocation (based on
          milestones) of our upcoming $WHALEZ coin.
        </p>
      </div>
    ),
  },
  {
    id: "referral",
    question: "5. Referral commission:",
    answer: (
      <div className="flex flex-col gap-4">
        <p>
          Once you mint our zkTogether NFT for a low fee of 0.005 ETH (public)
          or FREE / 0.004 ETH (
          <a
            href="https://opensea.io/collection/whaletogether"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            WhaleTogether
          </a>{" "}
          and{" "}
          <a
            href="https://opensea.io/collection/blackdust"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Black Dust
          </a>{" "}
          holders), you can refer others to mint via your unique referral link
          and earn a commission as follows:
        </p>
        <div className="overflow-x-auto mt-6">
          <table className="table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2">Benefit</th>
                <th className="px-4 py-2">WT/BD</th>
                <th className="px-4 py-2">Public</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-4 py-2">FREE Mint</td>
                <td className="border px-4 py-2">1 per NFT held</td>
                <td className="border px-4 py-2">No</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Mint Price</td>
                <td className="border px-4 py-2">0.004 ETH</td>
                <td className="border px-4 py-2">0.005 ETH</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Commission</td>
                <td className="border px-4 py-2">35%</td>
                <td className="border px-4 py-2">25%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    id: "whalez",
    question: "6. $WHALEZ coin:",
    answer: (
      <div className="flex flex-col gap-4">
        <p>
          {`It's meme coin season, isn't it? Well, we bring you something with a
          bit of a twist and a purpose.`}
        </p>
        <p>
          {`We're launching a coin VERY soon and the idea is to first launch on
          the Ethereum Mainnet (greater visibility and volume) and then bridge
          to other chains, starting with zkSync for people to accumulate those
          transactions for that upcoming airdrop.`}
        </p>
        <p>
          {`We've also taken inspiration from MongsNFT/$MONGS and will reward
          zkTogether Open Edition NFT, WhaleTogether and Black Dust NFT holders with an allocation of token based on milestones
          achieved as the coin reaches stability against "dumps".`}
        </p>
        <p>More info to come VERY soon!</p>
      </div>
    ),
  },
  {
    id: "mint",
    question: "7. How to bridge to and mint NFT on zkSync:",
    answer: (
      <div className="flex flex-col gap-4">
        <p>
          1. Add zkSync Era to your Metamask wallet. The quickest way to go to{" "}
          <a
            href="https://chainlist.org/?search=zksync"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Chainlist.org
          </a>{" "}
          and click “connect wallet” on the zkSync Era Mainnet.
        </p>
        <p>
          2. Use the official bridge at{" "}
          <a
            href="https://bridge.zksync.io/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            https://bridge.zksync.io/
          </a>{" "}
          to bridge ETH to zkSync (more expensive). You can also use{" "}
          <a
            href="https://orbiter.finance/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            https://orbiter.finance/
          </a>{" "}
          or{" "}
          <a
            href="https://multichain.org/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            https://multichain.org/
          </a>{" "}
          for cheaper bridging ETH to zkSync. It's just good you use the zkSync
          official bridge (more expensive) at some point for farming their
          airdrop. You can do it when gas is low in the future.
        </p>
        <p>
          3. Mint your NFTs by heading over to{" "}
          <Link to="/mint" className="underline">
            Mint page
          </Link>
        </p>
        <p>
          Alternatively, you can read this{" "}
          <a
            href="https://docs.google.com/document/d/1Qvec4SgWs8Dwq1Rf1eHqvdHmbdlfIny1FLKwVt0Zj-E/edit"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            document
          </a>{" "}
          for cheaper bridging options and how to add zkSync manually to
          Metamask if you don't want to use Chainlist's "one click" option.
        </p>
      </div>
    ),
  },
];

function RootPage() {
  return (
    <Layout>
      <div className={twMerge("flex justify-center items-center")}>
        <div className="text-center text-white">
          <div className="flex flex-col w-full mt-7 items-center text-white">
            <h1 className="font-goodgirl sm:block text-7xl sm:text-[7.5rem] md:text-[8rem] lg:text-[12rem] xl:text-[15rem] p-8 xl:p-10 mb-0 bg-clip-text text-transparent bg-gradient-primary tracking-normal">
              zkTogether
            </h1>
            <p className="text-2xl sm:text-3xl md:text-5xl w-[90%] lg:max-w-screen-lg">
              For the power of many is stronger than the power of one!
            </p>
            <Link to="/mint">
              <Button className="bg-gradient-primary font-bold text-white py-4 px-20 text-2xl sm:text-5xl mt-7 sm:mt-16">
                Mint
              </Button>
            </Link>
            <a href="#mint">
              <Button className="font-bold text-white py-4 px-20 text-lg md:text-2xl hover:bg-transparent">
                How to mint
              </Button>
            </a>
            <img
              src="/images/alphie-army.png"
              alt="Alphie Army"
              className="w-[90%] mt-16"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full py-44 items-center bg-black text-white">
        <div className="flex flex-col justify-center items-center mt-36 gap-32">
          <div className="text-center">
            <h4 className="text-3xl">Price</h4>
            <h3 className="text-5xl sm:text-8xl font-goodgirl pt-7 tracking-wide">
              FREE - 0.005 ETH
            </h3>
          </div>

          <div className="text-center">
            <h4 className="text-3xl">Supply</h4>
            <h3 className="text-5xl sm:text-8xl tracking-wide font-goodgirl pt-7">
              Open Edition
            </h3>
          </div>

          <div className="text-center">
            <h4 className="text-3xl">Chain</h4>
            <a
              href="https://zksync.io/"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              <h3 className="hover:scale-105 transform transition duration-300 ease-in-out text-8xl sm:text-9xl font-goodgirl pt-10 tracking-wide">
                zkSync
              </h3>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full py-20 md:py-44 items-center text-white bg-gradient-primary-alt">
        {/* <div className="flex flex-row justify-center items-center p-20"> */}
        <div className="flex flex-row justify-center items-center p-10 sm:p-20">
          <p className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl max-w-screen-xl leading-[2.3rem] sm:leading-[3rem] md:leading-[3.5rem] lg:leading-[4.5rem] xl:leading-[5.2rem]">
            zkTogether is an affordable Open Edition NFT brought to you by the
            WhaleTogether team to increase your chances at the highly
            anticipated zkSync airdrop.
          </p>
        </div>

        <div className="flex justify-center gap-3 lg:gap-7 my-5 md:my-32 w-screen">
          <img
            src="/images/whale168.gif"
            alt="zkTogether-nft"
            className="w-[90%] md:w-[30%] rounded-lg"
          />
          <img
            src="/images/whale168.gif"
            alt="zkTogether-nft"
            className="hidden md:block w-[30%] rounded-lg"
          />
          <img
            src="/images/whale168.gif"
            alt="zkTogether-nft"
            className="hidden md:block w-[30%] rounded-lg"
          />
          <img
            src="/images/whale168.gif"
            alt="zkTogether-nft"
            className="hidden md:block w-[30%] rounded-lg"
          />
          <img
            src="/images/whale168.gif"
            alt="zkTogether-nft"
            className="hidden md:block w-[30%] rounded-lg"
          />
        </div>

        <div className="flex flex-col justify-center items-center p-10 sm:p-20 md:mt-16">
          <p className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl max-w-screen-xl leading-[2.3rem] sm:leading-[2.7rem] md:leading-[3.5rem] lg:leading-[4.5rem] xl:leading-[5.2rem]">
            Our motto is "together, we're stronger" so the zkTogether NFT also
            qualifies you for an attractive referral commission and a potential
            allocation of our upcoming $WHALEZ Coin 👀
          </p>
          <div className="relative mt-10 sm:mt-24 mx-auto ml-5">
            <img
              src="/images/whale-rider.gif"
              alt="Alphie Army"
              className="w-[80%] absolute top-0 left-0"
            />
            <img
              src="/images/whale-rider.gif"
              alt="Alphie Army"
              className="w-[40%] absolute top-0 right-10 sm:right-20"
            />
            <img
              src="/images/whale-rider.gif"
              alt="Alphie Army"
              className="w-[60%] absolute top-28 sm:top-52 right-10"
            />
            <img
              src="/images/whale-rider.gif"
              alt="Alphie Army"
              className="w-[40%] relative top-0 left-0"
            />
            <img
              src="/images/whale-rider.gif"
              alt="Alphie Army"
              className="w-[30%] relative top-10 sm:top-20 left-5 sm:left-28"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full py-20 md:py-44 items-center text-white bg-black">
        <div className="w-[80%] sm:w-[90%] xl:max-w-5xl mx-auto xl:w-full">
          <h2 className="text-7xl sm:text-9xl font-bold mb-16">FAQs</h2>

          {faqs.map((faq, index) => (
            <FAQItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              id={faq.id}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-col 2xl:flex-row w-full py-44 justify-center items-center bg-gradient-primary-alt-2 text-white mx-auto">
        <img
          src="/images/zkTogether-head.png"
          alt="Alphie Army"
          className="lg:block w-[80%] sm:w-[50%] 2xl:w-[30rem] 2xl:ml-20"
        />
        <div className="flex flex-col items-center 2xl:items-start gap-10 text-left p-10 2xl:p-20 max-w-5xl">
          <p className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl max-w-screen-xl text-center 2xl:text-left font-medium sm:leading-[2.7rem] md:leading-[3.3rem] lg:leading-[4rem] xl:leading-[6rem] mb-3">
            So you're telling me I get a potential airdrop from zkSync and
            $WHALEZ for a tiny 0.005 ETH?
          </p>
          <p className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl xl:text-9xl max-w-screen-xl text-center 2xl:text-left font-medium">
            No brainer!
          </p>
          <Link to="/mint">
            <Button className="bg-white font-bold text-black mt-5 py-4 text-4xl sm:text-6xl px-20">{`LFG!`}</Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default RootPage;
