import { truncateAccount } from "../../utils";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useScramble } from "use-scramble";

const texts = [
  "Claiming...",
  "Pending...",
  "Working on it...",
  "Please wait..",
  "Hanging there...",
];

export default function TakingOverTxPending({
  mintData,
}: {
  mintData?: { hash: string };
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentText, setCurrentText] = useState("Please wait..");

  const { ref } = useScramble({
    text: currentText,
    scramble: 0,
    seed: 0,
    speed: 0.1,
    tick: 1,
    step: 4,
  });

  const { ref: ref2 } = useScramble({
    text: "Do NOT close your browser",
    scramble: 0,
    seed: 0,
    speed: 0.1,
    tick: 1,
    step: 4,
  });

  useEffect(() => {
    const updateText = () => {
      if (currentIndex < texts.length) {
        setCurrentText(texts[currentIndex]);
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentText(texts[0]);
        setCurrentIndex(0);
      }
    };

    const intervalId = setInterval(updateText, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentIndex]);

  return (
    <>
      <div
        className={`fixed w-screen h-screen overflow-hidden top-0 left-0 z-10 transition-opacity duration-300 ease-in-out bg-black flex justify-center items-center`}
      >
        <div className="text-center w-fit m-auto p-3 px-8 my-5 bg-black animate-pulse">
          <h1
            className={twMerge(
              "font-goodgirl text-3xl sm:text-8xl text-white mb-5",
            )}
            ref={ref}
          />
          <h4
            className={twMerge("text-sm sm:text-xl text-white mb-3")}
            ref={ref2}
          />
          <a
            href={`https://explorer.zksync.io/tx/${mintData?.hash}`}
            target="_blank"
            rel="noreferrer"
            className={twMerge("underline text-sm sm:text-md text-white")}
          >
            {truncateAccount(mintData?.hash)}
          </a>
        </div>
      </div>
    </>
  );
}
