import { twMerge } from "tailwind-merge";
import { AxiosError } from "axios";

import Button from "../shared/Button";
import ProjectDisclaimer from "../ProjectDisclaimer";
import { useAccount } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import CheckLikeRTBlock from "./CheckLikeRTBlock";
import CheckFollowsBlock from "./CheckFollowsBlock";
import { NotCompleteConditionsResponse } from ".";
import { useEffect, useState } from "react";
import useAuth from "../../modules/auth/useAuth";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

function RegisterSection({
  mintRegisterInfo,
  mintWLSummary,
  submitMintRegister,
  setStep,
}: {
  mintRegisterInfo: any;
  mintWLSummary: any;
  submitMintRegister: (
    walletAddress: `0x${string}` | undefined,
  ) => Promise<any>;
  setStep: (step: number) => void;
}) {
  const location = useLocation();
  const { userObj } = useAuth();
  const { openConnectModal } = useConnectModal();
  const { address } = useAccount();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notCompleteResp, setNotCompleteResp] =
    useState<NotCompleteConditionsResponse>();

  const [failCount, setFailCount] = useState(0);

  const [showElement, setShowElement] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowElement(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const register = async (e: any) => {
    e.stopPropagation();
    setIsSubmitting(true);

    try {
      await submitMintRegister(address);

      // reset not complete response
      setNotCompleteResp(undefined);
    } catch (err) {
      setNotCompleteResp(
        (err as AxiosError).response?.data as NotCompleteConditionsResponse,
      );
      const { msg } = (err as AxiosError).response
        ?.data as NotCompleteConditionsResponse;

      toast.error(msg);

      setFailCount(failCount + 1);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col w-full justify-center mt-3 mx-auto">
      <div className="bg-white text-black p-5 sm:p-10 w-[85%] sm:w-[90%] mx-auto rounded-lg">
        <h1 className="font-goodgirl text-7xl sm:text-[6rem] md:text-[7rem] lg:text-[9rem] 2xl:text-[10rem] py-7 pb-2 sm:py-10 sm:pb-5 xl:text-center tracking-normal">
          {userObj && userObj?.account?.twitter ? "Do These!" : "Get Started"}
        </h1>
        {openConnectModal ? (
          <>
            <h1 className="text-center text-2xl sm:text-3xl font-medium mb-3 italic">
              Connect wallet to continue 👇
            </h1>
            <Button
              className={twMerge(
                "bg-black text-white text-center w-full",
                "mb-5 sm:mb-0 uppercase font-semibold shadow-lg",
                "hover:scale-105 transform transition duration-300 ease-in-out text-lg sm:text-3xl py-5 sm:py-10",
              )}
              onClick={openConnectModal}
            >
              Connect Wallet
            </Button>
          </>
        ) : (
          <>
            {userObj && userObj?.account?.twitter ? (
              <div className="max-w-screen-md mx-auto">
                <div className="w-full">
                  <CheckFollowsBlock
                    requiredAccounts={mintRegisterInfo?.twitters}
                    isFollowing={notCompleteResp?.isFollowing}
                  />
                </div>
                <div className="w-full">
                  <CheckLikeRTBlock
                    requiredTweetId={mintRegisterInfo?.tweetId}
                    isComplete={
                      notCompleteResp?.isLikeTweet && notCompleteResp?.isRetweet
                    }
                  />
                </div>

                <Button
                  className="bg-gradient-primary font-bold text-white py-4 px-4 text-3xl sm:text-5xl w-full mt-7 sm:mt-12"
                  onClick={register}
                  disabled={isSubmitting || !userObj?.account?.twitter}
                >
                  Next
                </Button>
                {failCount > 20 && (
                  <Button
                    className="font-bold text-black py-4 px-4 text-lg sm:text-3xl w-full mt-3 hover:bg-white"
                    onClick={() => setStep(2)}
                  >
                    Skip?
                  </Button>
                )}
              </div>
            ) : (
              <div>
                <a
                  href={`${
                    process.env.REACT_APP_API_BASEURL
                  }/api/v2/auth/twitter?address=${address}&twRedirect=${location.pathname.substring(
                    1,
                  )}`}
                >
                  <>
                    <h1 className="text-center text-2xl sm:text-3xl font-medium mb-3 italic">
                      Yes, the lil bird 🐦 is required 👇
                    </h1>
                    <Button
                      className={twMerge(
                        "bg-black text-white text-center w-full",
                        "mb-5 sm:mb-0 uppercase font-semibold shadow-lg",
                        "hover:scale-105 transform transition duration-300 ease-in-out text-lg sm:text-3xl py-5 sm:py-10",
                      )}
                    >
                      Connect Twitter
                    </Button>
                  </>
                </a>
                {mintWLSummary ? (
                  <Button
                    className="font-bold text-black py-4 px-4 text-lg sm:text-3xl w-full mt-3 hover:bg-white"
                    onClick={() => setStep(2)}
                  >
                    Skip?
                  </Button>
                ) : (
                  <>
                    {showElement && (
                      <Button
                        className="font-bold text-black py-4 px-4 text-lg sm:text-3xl w-full mt-3 hover:bg-white"
                        onClick={() => setStep(2)}
                      >
                        Skip?
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <ProjectDisclaimer />
    </div>
  );
}

export default RegisterSection;
