import { useEffect, useState } from "react";
import { ethers } from "ethers";

import Button from "../shared/Button";
import Mint from "./Mint";
import { MintSectionProps } from "../../types/MintSectionType";
import Register from "./Register";
import { submitMintRegister } from "../../api/mintRegister";

export type NotCompleteConditionsResponse = {
  code: string | "MIN_ETH_BALANCE_NOT_REACHED";
  isFollowing?: boolean;
  isLikeTweet?: boolean;
  isRetweet?: boolean;
  msg: string;
  status: number;
};

function MintStart({
  mintRegisterInfo,
  mintRegisterSummary,
  mintWLSummary,
  saleConfig,
  userMintInfo,
  referralWalletAddress = ethers.constants.AddressZero,
  mintAmount = 10,
  errorCode,
  setMintAmount,
  setIsExpiredOnTheFly,
  decreaseMintAmount,
  increaseMintAmount,
  onMint,
  totalSupply = 0,
  setReferralWalletAddress,
}: MintSectionProps) {
  const [step, setStep] = useState(
    process.env.REACT_APP_SKIP_TWITTER === "true" ? 2 : 1,
  );

  useEffect(() => {
    if (mintRegisterSummary) {
      setStep(2);
    }
  }, [mintRegisterSummary]);

  return (
    <>
      {step === 1 && (
        <Register
          mintRegisterInfo={mintRegisterInfo}
          mintWLSummary={mintWLSummary}
          submitMintRegister={async (walletAddress) => {
            await submitMintRegister({ walletAddress });
            setStep(2);
          }}
          setStep={setStep}
        />
      )}
      {step === 2 && (
        <Mint
          mintRegisterInfo={mintRegisterInfo}
          mintWLSummary={mintWLSummary}
          saleConfig={saleConfig}
          userMintInfo={userMintInfo}
          referralWalletAddress={referralWalletAddress}
          mintAmount={mintAmount}
          errorCode={errorCode}
          setMintAmount={setMintAmount}
          setIsExpiredOnTheFly={setIsExpiredOnTheFly}
          decreaseMintAmount={decreaseMintAmount}
          increaseMintAmount={increaseMintAmount}
          onMint={onMint}
          totalSupply={totalSupply}
          setReferralWalletAddress={setReferralWalletAddress}
        />
      )}
    </>
  );
}

export default MintStart;
