import { NotCompleteConditionsResponse } from ".";
import StatusIndicator from "./StatusIndicator";

interface TwitterUser {
  displayName: string;
  username: string;
  imageUrl: string;
}

interface CheckFollowsBlockProps {
  requiredAccounts?: TwitterUser[];
  isFollowing?: NotCompleteConditionsResponse["isFollowing"];
}

const CheckFollowsBlock = ({
  requiredAccounts,
  isFollowing,
}: CheckFollowsBlockProps) => {
  return (
    <>
      <p className="text-xl sm:text-4xl mt-4 font-semibold">
        1. Follow:{" "}
        {requiredAccounts &&
          requiredAccounts.map((info) => {
            return (
              <span key={info.username}>
                <a
                  className="h-fit rounded-lg bg-white underline decoration-4 py-0 px-1 hover:animate-pulse bg-clip-text text-transparent bg-gradient-primary"
                  href={`https://twitter.com/intent/user?screen_name=${info.username}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {info.displayName}
                </a>

                {isFollowing !== undefined && (
                  <StatusIndicator isComplete={isFollowing} />
                )}
              </span>
            );
          })}
      </p>
    </>
  );
};

export default CheckFollowsBlock;
