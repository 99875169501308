import StatusIndicator from "./StatusIndicator";

interface CheckLikeRTBlockProps {
  requiredTweetId?: string;
  isComplete?: boolean;
}

const CheckLikeRTBlock = ({
  requiredTweetId,
  isComplete,
}: CheckLikeRTBlockProps) => {
  return (
    <p className="text-xl sm:text-4xl mt-4 font-semibold">
      2. Like & RT:{" "}
      <a
        className="h-fit rounded-lg bg-white underline decoration-4 py-0 px-2 hover:animate-pulse bg-clip-text text-transparent bg-gradient-primary"
        href={`https://twitter.com/intent/retweet?tweet_id=${requiredTweetId}`}
        target="_blank"
        rel="noreferrer"
      >
        This Post
      </a>
      {isComplete !== undefined && <StatusIndicator isComplete={isComplete} />}
    </p>
  );
};

export default CheckLikeRTBlock;
