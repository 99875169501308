import React, { useState } from "react";

import Button from "./shared/Button";
import { useAccount } from "wagmi";

export default function TxSuccess() {
  const { address } = useAccount();

  const websiteLink = `https://zk.whaletogether.com/mint/${address}`;
  const [opinionText] = useState(
    `Just grabbed the zkTogether Open Edition! Now I can't wait for the launch of $WHALEZ up next! 🚀`,
  );

  return (
    <>
      <div
        className={`fixed h-screen top-0 left-0 z-10 transition-opacity duration-300 ease-in-out bg-black`}
      >
        <div className="fixed w-screen h-screen top-0 left-0 z-10 text-center bg-gradient-primary p-10" />
        <div className="fixed top-0 left-0 z-50 text-center animate-slide-top bg-transparent flex flex-col w-full h-full justify-center items-center">
          <div className="flex flex-col justify-center items-center p-10">
            <div className="bg-white text-black p-10 rounded-lg">
              <h1 className="font-goodgirl mt-10 text-7xl sm:text-[7rem] xl:text-[8rem] 2xl:text-[10rem] mb-2 2xl:mb-5 text-center">
                Yaaasss!
              </h1>
              <div className="max-w-screen-xl mx-auto">
                <p className="text-2xl sm:text-4xl sm:mt-10">
                  You've just left your footprint on the zkSync chain which
                  could prove valuable when they launch their highly anticipated
                  airdrop. Mind spreading the word? Takes a few seconds! 👇
                </p>

                <Button
                  className="bg-gradient-primary text-white py-4 px-4 text-3xl sm:text-5xl w-full mt-7 sm:mt-14 font-medium"
                  onClick={() => {
                    window.open(
                      `https://twitter.com/share?url=${websiteLink}&text=${encodeURIComponent(
                        `${opinionText} #zkTogether`,
                      )}&via=WhaleTogether`,
                      "_blank",
                    );

                    window.location.reload();
                  }}
                >
                  Share on Twitter
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
