import { Fragment, ReactElement } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Icon from "./Icon";
import Button from "./Button";
import { twMerge } from "tailwind-merge";

interface PopupProps {
  popupToggle: boolean;
  togglePopupFn: () => void;
  headerText: string | ReactElement;
  descriptionText: ReactElement | string;
  image?: string;
  imageClassName?: string;
  bgColor?: string;
  textColor?: string;
  withButtonConfirm?: boolean;
  toggleConfirm?: () => void;
  isDisableConfirm?: boolean;
}
export default function BasePopup({
  popupToggle,
  togglePopupFn,
  headerText,
  descriptionText,
  image,
  imageClassName,
  bgColor = "bg-black",
  textColor = "text-white",
  withButtonConfirm = false,
  toggleConfirm,
  isDisableConfirm = false,
}: PopupProps) {
  return (
    <Transition.Root show={popupToggle} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={togglePopupFn}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-2xl ${bgColor} ${textColor} text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl md:max-5xl`}
              >
                <div className=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start px-6 py-14">
                    <Button
                      type="button"
                      onClick={togglePopupFn}
                      className="absolute right-3 top-0 sm:top-3 mt-3 px-4 py-2 font-bold text-2xl sm:mt-0 sm:ml-3 sm:w-auto hover:text-pink-500"
                    >
                      <Icon name="icon-x" className="text-4xl" />
                    </Button>
                    <div className="sm:flex sm:items-start gap-0">
                      <div className="mt-3 text-left sm:mt-0 sm:ml-4 w-full">
                        <h3 className="text-2xl font-bold mb-5 ">
                          {headerText}
                        </h3>
                        <div className="mt-2">
                          <h5 className="text-md">{descriptionText}</h5>
                        </div>
                      </div>

                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left self-end">
                        {image && (
                          <img
                            src={image}
                            alt="Hoppi"
                            className={twMerge(
                              "w-96 h-auto transform",
                              imageClassName,
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {withButtonConfirm && (
                    <div className="flex justify-end gap-2 mb-3">
                      <Button
                        type="button"
                        onClick={togglePopupFn}
                        className="flex items-center align-middle mt-1 px-4 py-2 font-bold text-lg bg-red-500 hover:bg-red-400 text-white rounded-xl"
                      >
                        <Icon name="icon-x-circle" className="text-xl mr-1" />
                        Cancel
                      </Button>
                      <Button
                        type="button"
                        onClick={toggleConfirm}
                        className="flex items-center align-middle mt-1 px-4 py-2 font-bold text-lg bg-black hover:bg-pink-500 text-white rounded-xl"
                        disabled={isDisableConfirm}
                      >
                        <Icon
                          name="icon-check-circle-broken"
                          className="text-xl mr-1"
                        />
                        Confirm
                      </Button>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
