import { threeXPAxios } from '../contexts/ConnectionContext';

interface GetMintRegisterInfoResponse {
  twitters: {
    displayName: string;
    username: string;
    imageUrl: string;
  }[];
  tweetId: string;
  startAt: string;
  endAt: string;
}

export interface GetMintRegistrationSummaryResponse {
  score: number;
  freeMintAmount: number;
  Referrals: string[];
  referrer: string;
  freeMintSignature: string;
}

export interface GetMintWLSummaryResponse {
  account: string;
  exclusive: {
    signature: string;
    balance: number;
  }
}

export const getMintRegisterInfo = async () => {
  const endpoint = '/whale168';

  return await threeXPAxios
    .get<GetMintRegisterInfoResponse>(endpoint)
    .then((x) => x.data);
};

export const getMintRegisterSummary = async (address: `0x${string}`) => {
  const endpoint = `/whale168/${address}`;

  return await threeXPAxios
    .get<GetMintRegistrationSummaryResponse>(endpoint)
    .then((x) => x.data);
};

export const getMintWLSummary = async (address: `0x${string}`) => {
  const endpoint = `/whale168/${address}/wl`;

  return await threeXPAxios
    .get<GetMintWLSummaryResponse>(endpoint)
    .then((x) => x.data);
};

export const submitMintRegister = async ({
  walletAddress,
}: {
  walletAddress?: string;
}) => {
  const endpoint = '/whale168';

  return await threeXPAxios.put(endpoint, { walletAddress }).then((x) => x.data);
};
