import Icon from "../shared/Icon";

const getCurrentYear = () => new Date().getFullYear();

export default function Footer() {
  return (
    <footer className="flex flex-col footer justify-center items-center bg-black text-white text-neutral-content w-full py-14">
      <a href="https://whaletogether.com/" target="_blank" rel="noreferrer">
        <img
          src="/images/whaletogether-logo.jpg"
          className="h-14 mb-3 rounded-full"
          alt="WhaleTogether Logo"
        />
      </a>

      <div className="mb-3 flex gap-3">
        <a
          href="https://twitter.com/WhaleTogether"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="icon-whale-twitter"
            className="text-xl hover:scale-110 transform transition duration-300 ease-in-out"
          />
        </a>
        <a
          href="https://discord.gg/whaletogether"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="icon-discord"
            className="text-xl hover:scale-110 transform transition duration-300 ease-in-out"
          />
        </a>
        <a
          href="https://opensea.io/collection/whaletogether"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="icon-opensea"
            className="text-xl hover:scale-110 transform transition duration-300 ease-in-out"
          />
        </a>
      </div>

      <p className="text-xs">
        Copyright © {getCurrentYear()} - All right reserved
      </p>
      <p className="text-xs mt-2">
        Built with 💜 by{" "}
        <a
          href="https://moonlabs.gg/"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          MoonLabs
        </a>
        <span className="relative -top-0.5">3</span>
      </p>
    </footer>
  );
}
