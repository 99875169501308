import Countdown from "react-countdown";

export default function CountDownTimerBlock({
  round = "Public",
  mintedAmount = 0,
  endAt,
  setIsExpiredOnTheFly,
}: {
  round: "Public" | "Exclusive";
  mintedAmount: number;
  endAt: string | Date | number | undefined;
  setIsExpiredOnTheFly: (on: boolean) => void;
}) {
  return (
    <div className="text-right absolute top-4 right-4 w-full">
      <h1 className="text-lg font-bold text-right">
        <span className="bg-clip-text text-transparent bg-gradient-primary">
          {round === "Public" ? "Public" : "⭐ Exclusive"}
        </span>{" "}
        | {mintedAmount.toLocaleString()} Minted
      </h1>

      {endAt && (
        <>
          {new Date(endAt).getTime() - new Date().getTime() <
            24 * 60 * 60 * 1000 && (
            <Countdown
              date={endAt}
              daysInHours
              className="text-4xl text-right font-bold"
              onComplete={() => {
                setIsExpiredOnTheFly(true);
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
