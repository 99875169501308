import { truncateAccount } from "../utils";
import { twMerge } from "tailwind-merge";
import LoadingRotatedArt from "./shared/LoadingView/LoadingRotatedArt";

export default function TxPending({
  mintData,
}: {
  mintData?: { hash: string };
}) {
  return (
    <>
      <div
        className={twMerge(
          "fixed top-0 left-0 w-screen h-screen overflow-hidden flex justify-center items-center bg-gradient-rare z-50 text-black",
        )}
      >
        <div className="flex flex-col justify-center items-center w-full p-3 z-20 animate-pulse">
          <div className="flex gap-20">
            <div className="inline-block">
              <LoadingRotatedArt />
            </div>
            <div className="hidden sm:inline-block">
              <LoadingRotatedArt />
            </div>
            <div className="hidden sm:inline-block">
              <LoadingRotatedArt />
            </div>
          </div>
          <h4
            className={twMerge(
              "font-goodgirl text-6xl sm:text-8xl md:text-[7rem] text-black mb-7",
            )}
          >
            Minting...
          </h4>
          <h4 className={twMerge("text-xl sm:text-3xl text-black mb-3")}>
            Do not close your browser
          </h4>
          <a
            href={`https://explorer.zksync.io/tx/${mintData?.hash}`}
            target="_blank"
            rel="noreferrer"
            className={twMerge("underline text-lg sm:text-2xl text-black")}
          >
            {truncateAccount(mintData?.hash)}
          </a>
        </div>
      </div>
    </>
  );
}
