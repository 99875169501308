import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import Navbar from "./Navbar";

import { useAccount, useNetwork } from "wagmi";
import { zkSync, zkSyncTestnet } from "wagmi/chains";
import WrongChainWarning from "./WrongChainWarning";
import Footer from "./Footer";

interface LayoutProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
  hideSection?: boolean;
}

const shouldDebugScreen =
  process.env.NODE_ENV === "development" ? "debug-screens" : "";

const chainId =
  process.env.REACT_APP_ENABLE_TESTNETS === "true"
    ? zkSyncTestnet.id
    : zkSync.id;

const Layout = ({
  children,
  hideSection = false,
  ...divProps
}: LayoutProps) => {
  const { isConnected } = useAccount();
  const { chain } = useNetwork();

  return (
    <>
      <div
        className={twMerge(
          "flex h-screen w-screen bg-black justify-center overflow-x-hidden",
          shouldDebugScreen,
          divProps.className,
        )}
      >
        <div className="w-screen max-w-screen-3xl">
          {/* top nav */}
          <Navbar />
          {isConnected && chain?.id !== chainId ? (
            <WrongChainWarning />
          ) : (
            <>
              {/* page content */}
              <>{children}</>
            </>
          )}
          {/* footer */}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
