import Icon from "../shared/Icon";

const StatusIndicator = ({ isComplete = false }: { isComplete: boolean }) => {
  if (isComplete) {
    return (
      <div className="text-2xl sm:text-4xl border border-white rounded-full text-center inline">
        <Icon name="icon-check-circle" />
      </div>
    );
  }

  return (
    <div className="text-2xl sm:text-4xl border border-white rounded-full text-center inline">
      <Icon name="icon-x-circle" />
    </div>
  );
};

export default StatusIndicator;
