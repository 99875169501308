const ProjectDisclaimer = () => {
  return (
    <>
      <div className="w-[90%] max-w-screen-lg mx-auto mt-5 sm:mt-5 mb-44 px-0 text-white">
        <p className="text-lg sm:text-2xl text-center">
          {`zkTogether is brought to you by`}{" "}
          <a
            href="https://twitter.com/WhaleTogether"
            target="_blank"
            rel="noreferrer"
            className="underline font-bold"
          >
            WhaleTogether
          </a>{" "}
          with love and care. No whales were harmed in the process. Get ready
          for $WHALEZ 🤑
        </p>
      </div>
    </>
  );
};

export default ProjectDisclaimer;
