import { DetailedHTMLProps, HTMLAttributes } from "react";
import LoadingRotatedArt from "./LoadingRotatedArt";

const LoadingView = (
  divProps: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) => (
  <div
    {...divProps}
    className={`flex h-full w-full flex-col items-center justify-center gap-3 -mt-36 ${divProps.className}`}
  >
    <LoadingRotatedArt />
    <span className="text-white text-3xl sm:text-4xl -mt-10">
      Prepping the engines...
    </span>
  </div>
);

export default LoadingView;
