import { twMerge } from "tailwind-merge";

export default function MintLoading() {
  return (
    <div
      className={twMerge(
        "fixed top-0 left-0 w-screen h-screen overflow-hidden flex justify-center items-center bg-black z-50 text-black",
      )}
    >
      <div className="flex flex-col justify-center items-center w-full p-3 z-20 animate-pulse">
        <h1 className="font-goodgirl p-10 sm:block text-6xl sm:text-8xl md:text-[7rem] mb-5 bg-clip-text text-transparent bg-gradient-primary">
          Confirm your transaction
        </h1>
      </div>
    </div>
  );
}
