import Button from "../shared/Button";

export default function RegistrationClosed() {
  return (
    <>
      <div
        className={`fixed h-screen top-0 left-0 z-10 transition-opacity duration-300 ease-in-out bg-black`}
      >
        <div className="fixed w-screen h-screen top-0 left-0 z-10 text-center bg-black p-10" />
        <div className="fixed top-0 left-0 z-50 text-center animate-slide-top bg-transparent flex flex-col w-full h-full justify-center items-center">
          <div className="flex flex-col justify-center items-center p-10">
            <div className="bg-white text-black p-10 rounded-lg">
              <h1 className="font-goodgirl mt-10 text-7xl sm:text-[7rem] xl:text-[8rem] 2xl:text-[10rem] mb-2 2xl:mb-5 text-center">
                Too Late!
              </h1>
              <div className="max-w-screen-xl mx-auto">
                <p className="text-2xl sm:text-4xl sm:mt-10">
                  {`All good things must come to an end and our Open Edition is now finished, BUT you can still`}
                </p>

                <a
                  href={`https://mintsquare.io/collection/zksync/${process.env.REACT_APP_NFT_CONTRACT_ADDRESS}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="bg-gradient-primary text-white py-4 px-4 text-3xl sm:text-5xl w-full mt-7 sm:mt-14">
                    Grab one here
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
