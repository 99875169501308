import Button from "../shared/Button";
import { Link } from "react-router-dom";

export default function TakingOverTxSuccess() {
  return (
    <>
      <div
        className={`fixed h-screen top-0 left-0 z-10 transition-opacity duration-300 ease-in-out`}
      >
        <div className="fixed w-screen h-screen top-0 left-0 z-10 text-center bg-gradient-primary p-10" />
        <div className="fixed top-0 left-0 z-50 text-center animate-slide-top bg-transparent flex flex-col w-full h-full justify-center items-center">
          <div className="flex flex-col justify-center items-center p-10">
            <div className="bg-white text-black p-10">
              <h1 className="font-goodgirl text-[7rem] xl:text-[8rem] 2xl:text-[10rem] mb-2 2xl:mb-5 text-center">
                Bravo!
              </h1>
              <div className="max-w-screen-md mx-auto">
                <p className="text-2xl">
                  {`Congrats on your referral payout! Enjoy the rewards 🎉 LET'S GO!`}
                </p>
                <Link to="/">
                  <Button className="bg-gradient-primary text-white py-4 px-4 text-3xl sm:text-5xl w-full mt-7 sm:mt-14 font-medium">
                    Back
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
