import BasePopup from "./shared/BasePopup";

interface PopupProps {
  popupToggle: boolean;
  togglePopupFn: () => void;
}
export default function BenefitPopup({
  popupToggle,
  togglePopupFn,
}: PopupProps) {
  return (
    <BasePopup
      popupToggle={popupToggle}
      togglePopupFn={togglePopupFn}
      headerText={`Benefits`}
      descriptionText={
        <>
          <div className="flex flex-col gap-4">
            <p>
              Once you mint our zkTogether NFT for a low fee of 0.005 ETH
              (public) or FREE / 0.004 ETH (
              <a
                href="https://opensea.io/collection/whaletogether"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                WhaleTogether
              </a>{" "}
              and{" "}
              <a
                href="https://opensea.io/collection/blackdust"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Black Dust
              </a>{" "}
              holders), you can refer others to mint via your unique referral
              link and earn a commission as follows:
            </p>
            <div className="overflow-x-auto mt-6">
              <table className="table-auto">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Benefit</th>
                    <th className="px-4 py-2">WT/BD</th>
                    <th className="px-4 py-2">Public</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border px-4 py-2">FREE Mint</td>
                    <td className="border px-4 py-2">1 per NFT held</td>
                    <td className="border px-4 py-2">No</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">Mint Price</td>
                    <td className="border px-4 py-2">0.004 ETH</td>
                    <td className="border px-4 py-2">0.005 ETH</td>
                  </tr>
                  <tr>
                    <td className="border px-4 py-2">Commission</td>
                    <td className="border px-4 py-2">35%</td>
                    <td className="border px-4 py-2">25%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      }
    />
  );
}
