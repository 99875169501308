import React, { useState } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Button from "../shared/Button";
import Icon from "../shared/Icon";
import { useAccount, useBalance, useDisconnect } from "wagmi";
import DropdownMenu from "../shared/DropdownMenu";

export default function ConnectWalletButton() {
  const [userMenuVisible, setUserMenuVisible] = useState(false);
  const { disconnect } = useDisconnect();
  const { address } = useAccount();
  const { data: balanceData } = useBalance({
    address,
    watch: true,
  });

  return (
    <>
      <ConnectButton.Custom>
        {({ account, chain, openChainModal, openConnectModal, mounted }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted;
          const connected = ready && account && chain;
          // (!authenticationStatus || authenticationStatus === 'authenticated');
          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <Button
                      onClick={openConnectModal}
                      className="bg-white text-black font-semibold sm:px-4 drop-shadow-xl hover:bg-gradient-primary hover:text-white"
                    >
                      <Icon
                        name="icon-whale-wallet"
                        className="mr-1 translate-y-0.5 text-2xl"
                      />{" "}
                      <span className="relative -top-0.5 fong-bold">
                        Connect
                      </span>
                    </Button>
                  );
                }

                if (chain.unsupported) {
                  return (
                    <Button
                      onClick={openChainModal}
                      className="bg-red-500 text-white font-semibold"
                    >
                      Wrong network
                    </Button>
                  );
                }

                return (
                  <div className="flex gap-4 items-center">
                    <Button
                      onClick={() => setUserMenuVisible((prev) => !prev)}
                      className="bg-gradient-primary text-white font-semibold drop-shadow-xl"
                    >
                      <Icon
                        name="icon-whale-eth"
                        className="text-xl relative top-0.5"
                      />{" "}
                      {parseFloat(balanceData?.formatted || "0").toFixed(3)}
                      <span className="mx-2">|</span>
                      <span className="">{account.displayName}</span>
                      <Icon
                        name="icon-chevron-down"
                        className="text-xl relative top-0.5 ml-1"
                      />
                    </Button>
                    <DropdownMenu
                      visible={userMenuVisible}
                      setVisible={setUserMenuVisible}
                      items={[
                        {
                          label: "Disconnect",
                          onClick: () => {
                            if (
                              window.confirm(
                                "Are you sure you want to disconnect the wallet?",
                              )
                            ) {
                              disconnect();
                            }
                            setUserMenuVisible(false);
                          },
                          className: "text-red-500 font-semibold",
                        },
                      ]}
                    />
                  </div>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </>
  );
}
