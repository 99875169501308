import Spritesheet from "react-responsive-spritesheet";

const LoadingRotatedArt = () => {
  return (
    <Spritesheet
      className="h-28 w-28 scale-150"
      image="/images/whale_loading_atlas_1.png"
      widthFrame={112}
      heightFrame={112}
      steps={9}
      fps={6}
      direction="forward"
      autoplay
      loop
    />
  );
};

export default LoadingRotatedArt;
