import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import {
  useAccount,
  useContractReads,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  ConnectorData,
} from "wagmi";
import { ethers } from "ethers";

import Layout from "../components/Layout";
import whale168ABI from "../utils/contracts/abis/whale168.json";
import LoadingView from "../components/shared/LoadingView";
import MintLoading from "../components/MintLoading";

import Button from "../components/shared/Button";
import BenefitPopup from "../components/BenefitPopup";
import ClaimTxPending from "../components/RewardsSection/ClaimTxPending";
import ClaimTxSuccess from "../components/RewardsSection/ClaimTxSuccess";
import toast from "react-hot-toast";
import { useConnectModal } from "@rainbow-me/rainbowkit";

const nftContractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS || "0x0";

const typeId = 0;

function Referral() {
  const { address, connector: activeConnector } = useAccount();
  const websiteLink = `https://zk.whaletogether.com/mint/${address}`;
  const [opinionText] = useState(
    `Just grabbed the zkTogether Open Edition! Now I can't wait for the launch of $WHALEZ up next! 🚀`,
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isPayoutActive, setIsPayoutActive] = useState<any>(false);
  const [userMintInfo, setUserMintInfo] = useState<any>({});

  const [popupToggle, setPopupToggle] = useState(false);

  const { openConnectModal } = useConnectModal();

  useContractReads({
    contracts: [
      {
        address: nftContractAddress as `0x${string}`,
        abi: whale168ABI,
        functionName: "isPayoutActive",
      },
      {
        address: nftContractAddress as `0x${string}`,
        abi: whale168ABI,
        functionName: "userMintInfo",
        args: [typeId, address],
      },
    ],
    onSuccess(data) {
      const [isPayoutActive, userMintInfo] = data;
      setIsPayoutActive(isPayoutActive);
      setUserMintInfo(userMintInfo);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    const handleConnectorUpdate = ({ account, chain }: ConnectorData) => {
      window.location.reload();
    };
    if (activeConnector) {
      activeConnector.on("change", handleConnectorUpdate);
    }
  }, [activeConnector]);

  const { config: publicConfig } = usePrepareContractWrite({
    address: nftContractAddress as `0x${string}`,
    abi: whale168ABI,
    functionName: "claimPayout",
    args: [typeId],
    enabled: !!openConnectModal,
  });
  const {
    data: claimPayoutData,
    isLoading: isClaiming,
    writeAsync: claimPayoutAsync,
  } = useContractWrite(publicConfig);

  const {
    data: claimPayoutDataTxData,
    isLoading: claimPayoutDataTxPending,
    isSuccess: claimPayoutDataTxSuccess,
  } = useWaitForTransaction({
    hash: claimPayoutData?.hash,
  });

  const onClaim = async (e: any) => {
    e.stopPropagation();
    if (isPayoutActive) {
      try {
        await claimPayoutAsync?.();
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const copyReferralLink = (e: any) => {
    e.stopPropagation();
    navigator.clipboard.writeText(websiteLink);
    toast.success("Copied to clipboard!");
  };

  const togglePopupFn = () => {
    setPopupToggle(!popupToggle);
  };

  return (
    <Layout>
      <>
        {isLoading && <LoadingView />}
        {isClaiming && <MintLoading />}
        {claimPayoutDataTxPending && claimPayoutData && (
          <ClaimTxPending mintData={claimPayoutData} />
        )}
        {claimPayoutDataTxSuccess && <ClaimTxSuccess />}
        {!isLoading && (
          <div className={twMerge("flex justify-center items-center")}>
            <div className="flex flex-col justify-center items-center w-full py-14 md:py-14 text-white bg-black">
              <div className="flex flex-col justify-center items-center text-center w-[80%] sm:w-[90%] xl:max-w-5xl xl:w-full">
                <h2 className="font-goodgirl text-7xl sm:text-9xl mb-16">
                  Rewards
                </h2>

                {openConnectModal ? (
                  <>
                    <h3 className="text-3xl font-medium mt-10">
                      Refer-2-earn!
                    </h3>
                    <h3 className="text-xl font-medium">
                      Earn a{" "}
                      <span className="underline">
                        {userMintInfo?.isExclusive ? "35%" : "25%"}
                      </span>{" "}
                      commission when your friends mint! 🎉
                    </h3>
                    {userMintInfo?.isExclusive ? (
                      <h3 className="text-2xl font-medium mt-5">
                        <span className="bg-clip-text text-transparent bg-gradient-primary">
                          ⭐ Exclusive
                        </span>{" "}
                        Member like you has more benefits{" "}
                        <span
                          className="underline cursor-pointer"
                          onClick={togglePopupFn}
                        >
                          learn more
                        </span>
                        !
                      </h3>
                    ) : (
                      <h3 className="text-2xl font-medium mt-5">
                        Learn more about your benefits{" "}
                        <span
                          className="underline cursor-pointer"
                          onClick={togglePopupFn}
                        >
                          here
                        </span>
                      </h3>
                    )}

                    <Button
                      className="bg-gradient-primary font-bold text-white py-4 px-4 text-5xl w-full mt-10"
                      onClick={openConnectModal}
                    >
                      Connect Wallet
                    </Button>
                  </>
                ) : (
                  <>
                    {userMintInfo && (
                      <>
                        <div className="flex flex-col gap-7">
                          <h3 className="text-3xl sm:text-5xl font-medium">
                            You Earned
                          </h3>
                          <div>
                            <h3 className="text-3xl sm:text-8xl font-medium">
                              {ethers.utils.formatEther(
                                userMintInfo?.revenueShareAmount,
                              )}{" "}
                              ETH
                            </h3>
                            <h3 className="text-2xl font-medium mt-1">
                              Lifetime Earnings:{" "}
                              {ethers.utils.formatEther(
                                userMintInfo?.revenueShareAmount +
                                  userMintInfo?.claimedRevenueShareAmount,
                              )}{" "}
                              ETH
                            </h3>
                          </div>
                          <Button
                            className="bg-gradient-primary text-white text-3xl sm:text-5xl  py-4 min-w-[50%] mx-auto font-medium"
                            onClick={onClaim}
                            disabled={
                              !isPayoutActive ||
                              ethers.utils.formatEther(
                                userMintInfo?.revenueShareAmount,
                              ) === "0.0"
                            }
                          >
                            {isPayoutActive ? "Claim" : "Payout is not active"}
                          </Button>
                        </div>
                        <h3 className="text-3xl font-medium mt-20">
                          Refer-2-earn!
                        </h3>
                        <h3 className="text-xl font-medium">
                          Earn a{" "}
                          <span className="underline">
                            {userMintInfo?.isExclusive ? "35%" : "25%"}
                          </span>{" "}
                          commission when your friends mint! 🎉
                        </h3>
                        <div className="flex gap-0 items-center justify-center mt-5">
                          <div className="bg-white text-black p-5 text-2xl rounded-l-lg font-medium w-[50%] overflow-hidden">
                            {websiteLink}
                          </div>
                          <div
                            className="bg-gradient-rare text-white p-5 text-2xl font-medium cursor-pointer"
                            onClick={copyReferralLink}
                          >
                            Copy
                          </div>
                          <div
                            onClick={() => {
                              window.open(
                                `https://twitter.com/share?url=${websiteLink}&text=${encodeURIComponent(
                                  `${opinionText} #zkTogether`,
                                )}&via=WhaleTogether`,
                                "_blank",
                              );

                              window.location.reload();
                            }}
                            className="bg-gradient-super-rare text-black p-5 text-2xl rounded-r-lg font-medium cursor-pointer"
                          >
                            Tweet
                          </div>
                        </div>
                        {userMintInfo?.isExclusive ? (
                          <h3 className="text-2xl font-medium mt-5">
                            <span className="bg-clip-text text-transparent bg-gradient-primary">
                              ⭐ Exclusive
                            </span>{" "}
                            Member like you has more benefits{" "}
                            <span
                              className="underline cursor-pointer"
                              onClick={togglePopupFn}
                            >
                              learn more
                            </span>
                            !
                          </h3>
                        ) : (
                          <h3 className="text-2xl font-medium mt-5">
                            Learn more about your benefits{" "}
                            <span
                              className="underline cursor-pointer"
                              onClick={togglePopupFn}
                            >
                              here
                            </span>
                          </h3>
                        )}
                      </>
                    )}
                  </>
                )}

                <img
                  src="/images/alphie-army.png"
                  alt="Alphie Army"
                  className="w-[90%] mt-16"
                />
              </div>
            </div>

            <BenefitPopup
              popupToggle={popupToggle}
              togglePopupFn={togglePopupFn}
            />
          </div>
        )}
      </>
    </Layout>
  );
}

export default Referral;
