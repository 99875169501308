import { twMerge } from "tailwind-merge";
import { BigNumber, ethers } from "ethers";

import Button from "../shared/Button";
import { MintSectionProps } from "../../types/MintSectionType";
import ProjectDisclaimer from "../ProjectDisclaimer";
import { useAccount, useBalance } from "wagmi";
import CountDownTimerBlock from "./CountDownTimerBlock";
import { useConnectModal } from "@rainbow-me/rainbowkit";

const maxMintAmount = 100;
const minMintAmount = 1;

function MintSection({
  mintRegisterInfo,
  mintWLSummary,
  saleConfig,
  userMintInfo,
  referralWalletAddress = ethers.constants.AddressZero,
  mintAmount = 10,
  errorCode,
  setMintAmount,
  setIsExpiredOnTheFly,
  decreaseMintAmount,
  increaseMintAmount,
  onMint,
  totalSupply = 0,
  setReferralWalletAddress,
}: MintSectionProps) {
  const { openConnectModal } = useConnectModal();
  const { address } = useAccount();
  const { data: balanceData } = useBalance({
    address,
    watch: true,
  });

  const unitPrice = mintWLSummary
    ? saleConfig.discountedUnitPrice
    : saleConfig.unitPrice;

  return (
    <div className="flex flex-col w-full justify-center mt-3">
      <div className="flex flex-col xl:flex-row gap-14 bg-white text-black px-5 py-20 pt-28 lg:px-20 sm:py-24 sm:pt-32 items-center relative w-[90%] xl:w-[100%] mx-auto rounded-lg">
        <CountDownTimerBlock
          round={mintWLSummary ? "Exclusive" : "Public"}
          mintedAmount={totalSupply}
          endAt={mintRegisterInfo.endAt}
          setIsExpiredOnTheFly={setIsExpiredOnTheFly}
        />
        <img
          src="/images/whale168.gif"
          alt="zkTogether-nft"
          className="sm:w-[30rem] xl:w-[25rem] 2xl:w-[35rem] shadow-md mt-5 sm:mt-0 rounded-lg"
        />

        <div>
          <div className="flex sm:hidden gap-2 justify-stretch">
            <Button
              className={`text-2xl text-white ${
                mintAmount === 1 ? "bg-gradient-primary" : "bg-black"
              }`}
              onClick={() => {
                setMintAmount(1);
              }}
              disabled={
                mintWLSummary?.exclusive?.balance -
                  userMintInfo?.freeClaimedAmount.toNumber() >
                1
              }
            >
              1
            </Button>
            <Button
              className={`text-2xl text-white ${
                mintAmount === 10 ? "bg-gradient-primary" : "bg-black"
              }`}
              onClick={() => {
                setMintAmount(10);
              }}
              disabled={
                mintWLSummary?.exclusive?.balance -
                  userMintInfo?.freeClaimedAmount.toNumber() >
                10
              }
            >
              10
            </Button>
            <Button
              className={`text-2xl text-white ${
                mintAmount === 50 ? "bg-gradient-primary" : "bg-black"
              }`}
              onClick={() => {
                setMintAmount(50);
              }}
              disabled={
                mintWLSummary?.exclusive?.balance -
                  userMintInfo?.freeClaimedAmount.toNumber() >
                50
              }
            >
              50
            </Button>
            <Button
              className={`text-2xl text-white ${
                mintAmount === 100 ? "bg-gradient-primary" : "bg-black"
              }`}
              onClick={() => {
                setMintAmount(100);
              }}
              disabled={
                mintWLSummary?.exclusive?.balance -
                  userMintInfo?.freeClaimedAmount.toNumber() >
                100
              }
            >
              100
            </Button>
          </div>
          <div className="flex items-center mt-3 sm:mt-14 xl:mt-20">
            <h1 className="text-3xl sm:text-5xl md:text-7xl lg:text-8xl font-bold text-left mb-1 sm:mb-3">
              Amount:
            </h1>
            <div className="-mt-1.5 relative">
              <div className="hidden sm:flex absolute left-0 -top-12 md:-top-14 gap-2">
                <Button
                  className={`text-2xl text-white ${
                    mintAmount === 1 ? "bg-gradient-primary" : "bg-black"
                  }`}
                  disabled={
                    mintWLSummary?.exclusive?.balance -
                      userMintInfo?.freeClaimedAmount.toNumber() >
                    1
                  }
                  onClick={() => {
                    setMintAmount(1);
                  }}
                >
                  1
                </Button>
                <Button
                  className={`text-2xl text-white ${
                    mintAmount === 10 ? "bg-gradient-primary" : "bg-black"
                  }`}
                  disabled={
                    mintWLSummary?.exclusive?.balance -
                      userMintInfo?.freeClaimedAmount.toNumber() >
                    10
                  }
                  onClick={() => {
                    setMintAmount(10);
                  }}
                >
                  10
                </Button>
                <Button
                  className={`text-2xl text-white ${
                    mintAmount === 50 ? "bg-gradient-primary" : "bg-black"
                  }`}
                  disabled={
                    mintWLSummary?.exclusive?.balance -
                      userMintInfo?.freeClaimedAmount?.toNumber() >
                    50
                  }
                  onClick={() => {
                    setMintAmount(50);
                  }}
                >
                  50
                </Button>
                <Button
                  className={`text-2xl text-white ${
                    mintAmount === 100 ? "bg-gradient-primary" : "bg-black"
                  }`}
                  disabled={
                    mintWLSummary?.exclusive?.balance -
                      userMintInfo?.freeClaimedAmount?.toNumber() >
                    100
                  }
                  onClick={() => {
                    setMintAmount(100);
                  }}
                >
                  100
                </Button>
              </div>
              <Button
                className={twMerge(
                  "text-3xl sm:text-5xl md:text-7xl lg:text-8xl select-none",
                  "hover:scale-105 transform transition duration-300 ease-in-out",
                  "disabled:opacity-50 disabled:bg-transparent",
                  "font-black",
                )}
                onClick={decreaseMintAmount}
                disabled={
                  errorCode !== "MINT_READY" ||
                  minMintAmount === mintAmount ||
                  mintAmount ===
                    mintWLSummary?.exclusive?.balance -
                      userMintInfo?.freeClaimedAmount.toNumber()
                }
              >
                -
              </Button>
              <h4 className="text-3xl sm:text-5xl md:text-7xl lg:text-8xl select-none tracking-wide font-bold inline">
                {mintAmount}
              </h4>
              <Button
                className={twMerge(
                  "text-3xl sm:text-5xl md:text-7xl lg:text-8xl select-none",
                  "hover:scale-105 transform transition duration-300 ease-in-out",
                  "disabled:opacity-50 disabled:bg-transparent",
                  "font-black",
                )}
                onClick={increaseMintAmount}
                disabled={
                  (errorCode !== "MINT_READY" &&
                    errorCode !== "TOO_LOW_MINT_AMOUNT") ||
                  maxMintAmount === mintAmount
                }
              >
                +
              </Button>
            </div>
          </div>
          <h1 className="text-3xl md:text-6xl lg:text-7xl mb-2 2xl:mb-5 font-bold text-left">
            Price:{" "}
            <>
              {!openConnectModal && userMintInfo ? (
                <>
                  {ethers.utils.formatEther(
                    BigNumber.from(
                      mintAmount -
                        (mintWLSummary
                          ? mintWLSummary?.exclusive?.balance -
                            userMintInfo?.freeClaimedAmount.toNumber()
                          : userMintInfo?.freeClaimedAmount.toNumber()),
                    ).mul(unitPrice),
                  ) === "0.0" ? (
                    <span className="font-goodgirl font-normal tracking-wider">
                      FREE
                    </span>
                  ) : (
                    <>
                      {ethers.utils.formatEther(
                        BigNumber.from(
                          mintAmount -
                            (mintWLSummary
                              ? mintWLSummary?.exclusive?.balance -
                                userMintInfo?.freeClaimedAmount.toNumber()
                              : userMintInfo?.freeClaimedAmount.toNumber()),
                        ).mul(unitPrice),
                      )}{" "}
                      ETH
                    </>
                  )}
                </>
              ) : (
                <>
                  {ethers.utils.formatEther(
                    BigNumber.from(mintAmount).mul(unitPrice),
                  )}{" "}
                  ETH
                </>
              )}
            </>
          </h1>
          {mintWLSummary && (
            <p className="text-md sm:text-xl font-medium text-left mt-7 mb-3">
              {userMintInfo?.freeClaimedAmount.toNumber() <
              mintWLSummary.exclusive.balance ? (
                <>
                  * You get{" "}
                  <span className="bg-clip-text text-transparent bg-gradient-primary">
                    {mintWLSummary?.exclusive?.balance} FREE Mint
                  </span>{" "}
                  and{" "}
                  <span className="bg-clip-text text-transparent bg-gradient-primary">
                    20% OFF 🎉
                  </span>{" "}
                  when you mint more. <br />
                  You should be proud of yourself 😉
                </>
              ) : (
                <>
                  * You get{" "}
                  <span className="bg-clip-text text-transparent bg-gradient-primary">
                    20% OFF 🎉
                  </span>{" "}
                  when you mint. You should be proud of yourself 😉
                </>
              )}
            </p>
          )}
          {!mintWLSummary && (
            <input
              type="text"
              value={
                referralWalletAddress !== ethers.constants.AddressZero
                  ? referralWalletAddress
                  : ""
              }
              className="border-4 border-black text-lg sm:text-4xl mt-5 px-4 w-full focus:border-primary-500 rounded-lg"
              placeholder="Referral Code (Optional)"
              onChange={(e) => setReferralWalletAddress(e.target.value)}
              max={8}
            />
          )}
          {openConnectModal ? (
            <Button
              className="bg-gradient-primary font-bold text-white py-4 px-4 text-5xl w-full mt-4"
              onClick={openConnectModal}
            >
              Connect Wallet
            </Button>
          ) : (
            <Button
              className="bg-gradient-primary font-bold text-white py-4 px-4 text-5xl w-full mt-4"
              onClick={onMint}
              disabled={
                errorCode !== "MINT_READY" ||
                mintAmount === 0 ||
                ethers.utils.formatEther(
                  BigNumber.from(
                    mintAmount -
                      (mintWLSummary
                        ? mintWLSummary?.exclusive?.balance -
                            userMintInfo?.freeClaimedAmount.toNumber() || 0
                        : userMintInfo?.freeClaimedAmount.toNumber() || 0),
                  ).mul(unitPrice),
                ) > ethers.utils.formatEther(balanceData?.value || 0)
              }
            >
              {errorCode === "MINT_READY" && (
                <>
                  {mintAmount === 0 && "Amount Too Low"}
                  {mintAmount > 0 &&
                    ethers.utils.formatEther(
                      BigNumber.from(
                        mintAmount -
                          (mintWLSummary
                            ? mintWLSummary?.exclusive?.balance -
                                userMintInfo?.freeClaimedAmount.toNumber() || 0
                            : userMintInfo?.freeClaimedAmount.toNumber() || 0),
                      ).mul(unitPrice),
                    ) <= ethers.utils.formatEther(balanceData?.value || 0) &&
                    "Mint"}

                  {mintAmount > 0 &&
                    ethers.utils.formatEther(
                      BigNumber.from(
                        mintAmount -
                          (mintWLSummary
                            ? mintWLSummary?.exclusive?.balance -
                                userMintInfo?.freeClaimedAmount.toNumber() || 0
                            : userMintInfo?.freeClaimedAmount.toNumber() || 0),
                      ).mul(unitPrice),
                    ) > ethers.utils.formatEther(balanceData?.value || 0) &&
                    "Insufficient Balance"}
                </>
              )}
              {errorCode === "NOT_STARTED" && "Mint Not Started"}
            </Button>
          )}
        </div>
      </div>
      <ProjectDisclaimer />
    </div>
  );
}

export default MintSection;
