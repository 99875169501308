import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import {
  useAccount,
  useContractReads,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useQuery,
  ConnectorData,
} from "wagmi";
import { ethers } from "ethers";

import { MintErrorCodeType } from "../types/MintErrorCodeType";
import Layout from "../components/Layout";
import whale168ABI from "../utils/contracts/abis/whale168.json";
import LoadingView from "../components/shared/LoadingView";
import MintLoading from "../components/MintLoading";
import TxPending from "../components/TxPending";
import TxSuccess from "../components/TxSuccess";
import MintSection from "../components/MintSection";
import {
  getMintRegisterInfo,
  getMintWLSummary,
  getMintRegisterSummary,
} from "../api/mintRegister";
import RegistrationClosed from "../components/MintSection/RegistrationClosed";

const nftContractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS || "0x0";

const typeId = 0;

const maxMintAmount = 100;
const minMintAmount = 0;

function Sale() {
  let { referralAddress } = useParams();
  const { address, connector: activeConnector } = useAccount();
  const [isLoading, setIsLoading] = useState(true);
  const [errorCode, setErrorCode] = useState<MintErrorCodeType>("NOT_STARTED");
  const [mintAmount, setMintAmount] = useState(10);
  const [saleConfig, setSaleConfig] = useState<any>({});
  const [userMintInfo, setUserMintInfo] = useState<any>({});
  const [totalSupply, setTotalSupply] = useState<any>(0);
  const [referralWalletAddress, setReferralWalletAddress] = useState(
    ethers.constants.AddressZero,
  );
  const [isExpiredOnTheFly, setIsExpiredOnTheFly] = useState(false);

  const { data: mintRegisterInfo, isLoading: isMintRegisterInfoLoading } =
    useQuery(["mintRegisterInfo"], async () => getMintRegisterInfo(), {
      refetchOnWindowFocus: false,
    });

  const { data: mintWLSummary, isLoading: isLoadingMintWLSummary } = useQuery(
    ["mintWLSummary"],
    async () => getMintWLSummary(address as `0x${string}`),
    {
      refetchOnWindowFocus: true,
      enabled: !!address && !isLoading,
      onSuccess: () => {
        if (mintWLSummary && !isLoading) {
          setMintAmount(
            mintWLSummary.exclusive.balance ||
              0 - userMintInfo?.freeClaimedAmount?.toNumber() ||
              0,
          );
        }
      },
    },
  );

  const { data: mintRegisterSummary, isLoading: isLoadingMintRegisterSummary } =
    useQuery(
      ["mintRegisterSummary"],
      async () => getMintRegisterSummary(address as `0x${string}`),
      {
        refetchOnWindowFocus: false,
        enabled: !!address,
      },
    );

  useContractReads({
    contracts: [
      {
        address: nftContractAddress as `0x${string}`,
        abi: whale168ABI,
        functionName: "getSaleConfig",
        args: [typeId],
      },
      {
        address: nftContractAddress as `0x${string}`,
        abi: whale168ABI,
        functionName: "userMintInfo",
        args: [typeId, address],
      },
      {
        address: nftContractAddress as `0x${string}`,
        abi: whale168ABI,
        functionName: "totalSupply",
        args: [typeId],
      },
    ],
    onSuccess(data) {
      const [saleConfig, userMintInfo, totalSupply] = data;

      setSaleConfig(saleConfig);
      setUserMintInfo(userMintInfo);
      setTotalSupply(totalSupply);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (referralAddress) {
      try {
        setReferralWalletAddress(ethers.utils.getAddress(referralAddress));
      } catch (error) {
        // throw new HttpException('Invalid wallet address', HttpStatus.BAD_REQUEST);
      }
    }
  }, [referralAddress]);

  useEffect(() => {
    const handleConnectorUpdate = ({ account, chain }: ConnectorData) => {
      window.location.reload();
    };
    if (activeConnector) {
      activeConnector.on("change", handleConnectorUpdate);
    }
  }, [activeConnector]);

  useEffect(() => {
    if (totalSupply) {
      if (!saleConfig?.enabled && totalSupply?.toNumber() === 0) {
        return setErrorCode("NOT_STARTED");
      }
      if (!saleConfig?.enabled && totalSupply?.toNumber() > 0) {
        return setErrorCode("MINT_CLOSED");
      }
    }
    if (saleConfig?.enabled) {
      return setErrorCode("MINT_READY");
    }
  }, [saleConfig, totalSupply]);

  const isLoadingData =
    isLoading || isLoadingMintWLSummary || isMintRegisterInfoLoading;

  const isRegistrationExpired = useMemo<boolean>(() => {
    if (isExpiredOnTheFly) return true;
    if (isLoadingData) {
      return false;
    }

    return mintRegisterInfo?.endAt
      ? Date.now() > new Date(mintRegisterInfo.endAt).getTime()
      : false;
  }, [isLoadingData, mintRegisterInfo?.endAt, isExpiredOnTheFly]);

  // exclusive mint
  const { config: exclusiveConfig } = usePrepareContractWrite({
    address: nftContractAddress as `0x${string}`,
    abi: whale168ABI,
    functionName: "exclusiveMint",
    args: [
      typeId,
      mintAmount,
      mintWLSummary?.exclusive?.balance,
      mintWLSummary?.exclusive?.signature,
    ],
    overrides: {
      from: address,
      value:
        saleConfig &&
        ethers.utils
          .parseUnits(saleConfig?.discountedUnitPrice?.toString() || "0", 0)
          .mul(
            ethers.utils.parseUnits(
              (
                mintAmount -
                  ((mintWLSummary?.exclusive?.balance || 0) -
                    userMintInfo?.freeClaimedAmount?.toNumber()) || 0
              ).toString(),
              0,
            ),
          ),
    },
    enabled: !!mintWLSummary,
  });

  const {
    data: exclusiveMintData,
    isLoading: isExclusiveMinting,
    writeAsync: exclusiveMintAsync,
  } = useContractWrite(exclusiveConfig);

  const {
    data: exclusiveMintTxData,
    isLoading: exclusiveMintTxPending,
    isSuccess: exclusiveMintTxSuccess,
  } = useWaitForTransaction({
    hash: exclusiveMintData?.hash,
  });

  // public mint
  const { config: publicConfig } = usePrepareContractWrite({
    address: nftContractAddress as `0x${string}`,
    abi: whale168ABI,
    functionName: "mint",
    args: [typeId, mintAmount, referralWalletAddress],
    overrides: {
      from: address,
      value:
        saleConfig &&
        ethers.utils
          .parseUnits(saleConfig?.unitPrice?.toString() || "0", 0)
          .mul(ethers.utils.parseUnits(mintAmount.toString(), 0)),
    },
  });
  const {
    data: publicMintData,
    isLoading: isPublicMinting,
    writeAsync: publicMintAsync,
  } = useContractWrite(publicConfig);

  const {
    data: publicMintTxData,
    isLoading: publicMintTxPending,
    isSuccess: publicMintTxSuccess,
  } = useWaitForTransaction({
    hash: publicMintData?.hash,
  });

  const onMint = async (e: any) => {
    e.stopPropagation();
    if (saleConfig.enabled) {
      try {
        if (mintWLSummary) {
          await exclusiveMintAsync?.();
        } else {
          await publicMintAsync?.();
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const decreaseMintAmount = () => {
    if (mintAmount > minMintAmount) {
      setMintAmount(mintAmount - 1);
    }
  };
  const increaseMintAmount = () => {
    if (mintAmount < maxMintAmount) {
      setMintAmount(mintAmount + 1);
    }
  };

  return (
    <Layout>
      <>
        {isLoadingData && <LoadingView />}
        {!isLoadingData && (
          <div className={twMerge("flex justify-center items-center")}>
            <div className="text-center">
              {!isLoadingData && (
                <div className={twMerge("flex justify-center items-center")}>
                  {isLoadingData && <LoadingView />}
                  {!isLoadingData && isRegistrationExpired && (
                    <div className="flex gap-26 w-screen  justify-center items-center">
                      <RegistrationClosed />
                    </div>
                  )}
                  {(isPublicMinting || isExclusiveMinting) && <MintLoading />}
                  {((publicMintTxPending && publicMintData) ||
                    (exclusiveMintTxPending && exclusiveMintTxData)) && (
                    <TxPending mintData={publicMintData} />
                  )}
                  {(publicMintTxSuccess || exclusiveMintTxSuccess) && (
                    <TxSuccess />
                  )}

                  <div className="text-center">
                    {!isLoadingData && !isRegistrationExpired && (
                      <MintSection
                        mintRegisterInfo={mintRegisterInfo}
                        mintRegisterSummary={mintRegisterSummary}
                        mintWLSummary={mintWLSummary}
                        saleConfig={saleConfig}
                        userMintInfo={userMintInfo}
                        referralWalletAddress={referralWalletAddress}
                        mintAmount={mintAmount}
                        errorCode={errorCode}
                        setMintAmount={setMintAmount}
                        setIsExpiredOnTheFly={setIsExpiredOnTheFly}
                        decreaseMintAmount={decreaseMintAmount}
                        increaseMintAmount={increaseMintAmount}
                        onMint={onMint}
                        totalSupply={totalSupply}
                        setReferralWalletAddress={setReferralWalletAddress}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </Layout>
  );
}

export default Sale;
